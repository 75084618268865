import { ContextualInfoButton } from "@/components/buttons/ContextualInfoButton";
import { getTheme } from "@/features/branding";
import { HacsJobSchema } from "@/features/hacs";
import { logToolUsage } from "@/features/tracking";
import { BlazeJobTable } from "@/tools/aggregate/blaze/components/BlazeJobTable";
import { Tool } from "@/types/tools";
import { BookOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { FC, useState } from "react";
import { v4 } from "uuid";
import { PortfolioAnalysisNewRunModal } from "./components/PortfolioAnalysisNewRunModal";
interface RunsTableTitleProps {
  onRunStart: (schema: HacsJobSchema) => void;
}
const buildPortfolioAnalysisTool = (hazards?: string[]): Tool => {
  const RunsTableTitle = ({
    onRunStart
  }: RunsTableTitleProps) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalKey, setModalKey] = useState<string>();
    const openModal = () => {
      setModalKey(v4());
      setIsModalOpen(true);
    };
    return <Row justify="space-between" style={{
      marginBottom: ".5rem"
    }} data-sentry-element="Row" data-sentry-component="RunsTableTitle" data-sentry-source-file="portfolio_analysis.tsx">
        <Button onClick={openModal} type="primary" data-sentry-element="Button" data-sentry-source-file="portfolio_analysis.tsx">
          Start New Run
        </Button>
        <ContextualInfoButton className="" id="portfolioAnalysisResultsExplanation" type="link" style={{
        color: getTheme().antd.token?.colorPrimary
      }} data-sentry-element="ContextualInfoButton" data-sentry-source-file="portfolio_analysis.tsx">
          What do my results mean?
        </ContextualInfoButton>
        <PortfolioAnalysisNewRunModal footer={<></>} key={modalKey} hazards={hazards} onRunStart={schema => {
        onRunStart(schema);
        setIsModalOpen(false);
        logToolUsage({
          id: "aggregate.portfolioAnalysis",
          type: "portfolio_analysis",
          count: schema.lineCount
        });
      }} open={isModalOpen} onCancel={() => setIsModalOpen(false)} data-sentry-element="PortfolioAnalysisNewRunModal" data-sentry-source-file="portfolio_analysis.tsx" />
      </Row>;
  };
  const CommonPortfolioAnalysisComponent = () => {
    return <BlazeJobTable failModeId="debugInfo" headerComponent={RunsTableTitle as FC} localStorageKey="commonPortfolioAnalysisRuns" data-sentry-element="BlazeJobTable" data-sentry-component="CommonPortfolioAnalysisComponent" data-sentry-source-file="portfolio_analysis.tsx" />;
  };
  return {
    id: "portfolioAnalysis",
    category: "aggregate",
    keyPrefix: "aggregate.commonPortfolioAnalysis",
    icon: <BookOutlined />,
    render: () => <CommonPortfolioAnalysisComponent />,
    showUsage: true
  };
};
export default buildPortfolioAnalysisTool;