import { defaultOfferingOfTools } from "@/tools/default_offering";
import { merge } from "lodash/fp";
import { HubThemePartial } from "../../types";
import xdiTheme from "../xdi";
import defaultTheme from "../_default";

// This is the theme for the Common Hub - the Hub which will be used for many
// clients including Marsh and Baringa.
const commonThemeBase: HubThemePartial = merge(defaultTheme, {
  meta: {
    name: xdiTheme.meta?.name,
    hasFontStyles: xdiTheme.meta?.hasFontStyles,
    config: {
      featureSet: {
        comms: {
          feedback: {
            enabled: true,
          },
          support: {
            enabled: true,
          },
        },
        docs: {
          contextualElementTutorials: {
            enabled: true,
          },
          toolPageTutorials: {
            enabled: true,
          },
        },
        tools: [...defaultOfferingOfTools],
        tracking: {
          hubspot: {
            enabled: true,
            allowEmbedCode: false,
            allowToolTracking: true,
            pipeline: {
              id: "49578663", // "Hub Usage Pipeline"
              stage: "101746048", // "Hub Analysis Done (Hub Usage Pipeline)""
            },
          },
          plausible: {
            enabled: true,
            allowLocalTracking: false,
            siteDomain: "hub.climaterisk.net",
          },
        },
      },
      locale: {
        defaultLanguage: "en-AU",
      },
    },
    logoUrl: xdiTheme.meta?.logoUrl,
    logoIconUrl: xdiTheme.meta?.logoIconUrl,
    backgroundImage: xdiTheme.meta?.backgroundImage,
    backgroundColor: xdiTheme.meta?.backgroundColor,
  },
  antd: xdiTheme.antd,
} as HubThemePartial);

export default commonThemeBase;
